import React from 'react';
import { useRouter } from 'next/router';
import { SidebarType } from 'types/Account/SidebarTypes';
import { UseStyles } from 'hooks';
import Button from '@mui/material/Button';

import {
  AccountIcon,
  AirplaneIcon,
  LoginIcon,
  VerificationIcon,
  PlanIcon,
  CardIcon,
  PaymentIcon,
  ProgramsIcon,
  ConnectedIcon,
  ContactIcon,
  PrivacyIcon,
  PresentIcon,
} from 'theme/icons';
import { useMemberApi } from 'hooks/useMemberApi';
import {
  MemberAdminRolesSetInputRolesEnum,
  MemberInfoOutput,
  MemberProfileOutput,
  MemberSubscriptionInfoOutput,
} from 'services/api/client';
import $account from 'services/Account';
import withPathPrefix from 'utils/withPathPrefix';
import Config from 'services/Config';
import { ensureText, ensureUrl } from 'utils';
import { ConfirmDialog } from 'containers/Dialog';
import { useStoreon } from 'storeon/react';
import AdminApi from 'services/api/admin';
import { Avatar } from '@ui/molecules/Avatar/Avatar';
import { SidebarMember } from '@ui/organisms/SidebarMember/SidebarMember';
import { UserName } from '@ui/molecules/UserName/UserName';
import { SidebarLabelWithValue } from '@ui/molecules/SidebarLabelWithValue/SidebarLabelWithValue';
import { SidebarMenu } from '@ui/organisms/SidebarMenu/SidebarMenu';
import { SidebarMobileMenu } from '@ui/organisms/SidebarMobileMenu/SidebarMobileMenu';
import { SidebarIconDataType } from '@ui/molecules/SidebarIcon/SidebarIcon.interface';
import { useCheckoutApi } from 'hooks/useCheckoutApi';

const menuItems: Array<SidebarIconDataType> = [
  {
    icon: <AccountIcon />,
    label: 'Manage Account',
    appPath: '/profile',
  },
  {
    icon: <LoginIcon />,
    label: 'Login & Security',
    appPath: '/login',
  },
  {
    icon: <VerificationIcon />,
    label: 'Verification',
    appPath: '/verification-status',
    access: () => !$account.roleHhm(),
  },
  {
    icon: <PlanIcon />,
    label: 'Manage Plan',
    appPath: '/manage-plan',
    access: () => !$account.roleHhm(),
  },
  {
    icon: <CardIcon />,
    label: `${ensureText('WeSalute+')} Card`,
    appPath: '/wesalute-card',
    access: () => $account.roleVr(),
  },
  {
    icon: <PresentIcon />,
    label: 'Give Back',
    appPath: '/give-back',
    access: () => !$account.roleHhm(),
  },
  {
    icon: <PaymentIcon />,
    label: 'Payment Methods',
    appPath: '/payment-and-shipping',
    access: () => !$account.roleHhm(),
  },
  {
    icon: <AirplaneIcon />,
    label: 'Travel Protection Benefits',
    appPath: '/travel-protection-benefits',
    access: (data, info) => {
      return !!info?.travel_protection && !!data && !$account.roleHhm();
    },
  },
  {
    icon: <ProgramsIcon />,
    label: 'Programs',
    appPath: '/programs',
    access: (data) => !!data?.mrp && !$account.roleHhm(),
  },
  {
    icon: <ConnectedIcon />,
    label: 'Connected Apps & Accounts',
    appPath: '/brand-connections',
  },
  {
    icon: <ContactIcon />,
    label: 'Contact Preferences',
    appPath: '/communication-preferences',
  },
  {
    icon: <PrivacyIcon />,
    label: 'Data & Privacy',
    appPath: '/data-privacy',
    access: () => !$account.roleHhm(),
  },
];

export function Sidebar({ className }: SidebarType): JSX.Element {
  const classes = UseStyles();
  const router = useRouter();
  const { dispatch } = useStoreon();
  const { data } = useMemberApi<MemberProfileOutput>('memberProfile');
  const { data: info, mutate } = useMemberApi<MemberInfoOutput>('memberInfo');

  const filteredMenuItems = (): Array<SidebarIconDataType> => {
    return menuItems.filter(({ access }) => !access || access(data, info));
  };
  const routerAsPath = router.asPath.split('?')[0];
  const titleMap = {
    year: 'Annual',
    month: 'Monthly',
    trial: 'Trial',
    '3year': '3 Year',
    '5year': '5 Year',
    lifetime: 'Lifetime',
  };
  const userRoles = (info?.roles ?? []) as MemberAdminRolesSetInputRolesEnum[];
  const userIsAdmin = userRoles.includes(MemberAdminRolesSetInputRolesEnum.Administrator);
  return (
    <div className={className}>
      <SidebarMobileMenu routerAsPath={routerAsPath} items={filteredMenuItems()} />
      <SidebarMember>
        <Avatar
          profilePhotoUrl={
            data?.profile_photo
              ? (ensureUrl(Config.getSync('api_base_url')) || withPathPrefix('/api')) + data?.profile_photo
              : undefined
          }
        />
        {info && <UserName firstName={info.first_name} lastName={info.last_name} verified={info.verified} />}
        {info?.member_id && <SidebarLabelWithValue label='MEMBER ID' value={info.member_id} />}
        {info?.travel_code && info?.plan_type && <SidebarLabelWithValue label='Travel Code' value={info.travel_code} />}
        <SidebarLabelWithValue
          label='TRAVEL PROTECTION'
          value={
            info?.travel_protection ? (
              <>
                <div className={classes.accountSidebarMemberInfoTravelProtection}>
                  Travel ID <span>{info.travel_protection}</span>
                </div>
              </>
            ) : (
              'Inactive'
            )
          }
          button='Manage'
          buttonLink='/manage-plan'
          buttonAccess={!$account.roleHhm()}
        />
        <SidebarLabelWithValue
          label='Plan'
          value={info?.plan_type ? titleMap[info.plan_type] : 'None'}
          button='Update'
          buttonLink='/manage-plan'
          buttonAccess={!$account.roleHhm()}
        />
        {$account.roleAdmin() && !!info?.primary_member && (
          <>
            <SidebarLabelWithValue
              label='Primary Member'
              value={`${info.primary_member.first_name} ${info.primary_member.last_name}`}
              button='Masquerade'
              buttonOnClick={() => dispatch('dialog/open', { id: 'swapMasquerade' })}
              buttonAccess={$account.roleAdmin()}
            />
            <SidebarLabelWithValue label='Primary Email' value={info.primary_member.email!} />
          </>
        )}
        {info?.masqueraded && $account.roleSuperAdmin() && (
          <Button
            onClick={() => {
              dispatch('dialog/open', { id: 'manageAdminRole' });
            }}
            className={classes.accountSidebarMemberAdminLink}
          >
            {userIsAdmin ? 'Disable' : 'Enable'} Admin
          </Button>
        )}
      </SidebarMember>

      <SidebarMenu items={filteredMenuItems()} routerAsPath={routerAsPath} />

      {$account.roleSuperAdmin() && (
        <>
          <ConfirmDialog
            name='manageAdminRole'
            title={`${userIsAdmin ? 'Disable' : 'Enable'} Admin`}
            description={`Are you sure you would like to ${
              userIsAdmin ? 'disable' : 'give'
            } Admin access to this user?`}
            successMessage={'Your have successfully updated Admin access for this user.'}
            failureMessage={`There was a problem ${
              userIsAdmin ? 'disabling' : 'enabling'
            } Admin access. Please try again.`}
            handle={async () => {
              await AdminApi.memberAdminRolesSet({
                roles: userIsAdmin ? [] : [...userRoles, MemberAdminRolesSetInputRolesEnum.Administrator],
              });
              await mutate();
            }}
            buttons={[
              { label: 'Yes, continue', action: 'confirm', variant: 'contained' },
              { label: 'Cancel', action: 'close', variant: 'outlined' },
            ]}
          />
        </>
      )}

      {$account.roleAdmin() && (
        <>
          <ConfirmDialog
            name='swapMasquerade'
            title={'Swap Masquerade'}
            subTitle={'Are you sure you want to masquerade under a different member?'}
            description={'This will navigate you out of this member’s account and into the primary’s user account.'}
            handle={() =>
              router.push(`${process.env.NEXT_PUBLIC_ADMIN_APP_URL}/masquerade/${info?.primary_member?.id}`)
            }
            buttons={[
              { label: 'Yes, continue', action: 'confirm', variant: 'contained' },
              { label: 'Cancel', action: 'close', variant: 'outlined' },
            ]}
          />
        </>
      )}
    </div>
  );
}
