import React from 'react';
import Link from 'next/link';
import styles from './SidebarIcon.module.scss';
import { SidebarIconProps } from './SidebarIcon.interface';

export const SidebarIcon = ({ label, icon, appPath, routerAsPath, onClick }: SidebarIconProps): JSX.Element => {
  return (
    <Link href={appPath}>
      <a
        className={`${styles.link} ${routerAsPath == appPath ? styles.active + ' active' : ''} link`}
        onClick={onClick}
      >
        {React.cloneElement(icon, { className: styles.icon + ' icon' })}
        {label}
      </a>
    </Link>
  );
};
